import { useContext, useEffect, useState } from "react";
import { Context } from "../../../utils/context";
import Notification from "../../shared/Notification";
import GeneratingLoader from "./GeneratingLoader";
import "./Generating.scss";

const Generating = () => {
  const context = useContext(Context);

  useEffect(() => {
    const animateTo100 = () => {
      let currentProgress = context.progress;
      const targetProgress = 100;
      const duration = 8000; // 8 seconds
      const frames = 60; // Approximate animation frames
      const increment = (targetProgress - currentProgress) / frames;
      const intervalTime = duration / frames;

      const smoothInterval = setInterval(() => {
        currentProgress += increment;
        context.setProgress(Math.min(100, Math.floor(currentProgress)));
        if (currentProgress >= 100) clearInterval(smoothInterval);
      }, intervalTime);
    };

    let interval;
    const targetDuration = 2 * 60 * 1000; // 2 minutes in milliseconds
    const maxProgress = 99;
    const incrementRate = targetDuration / maxProgress;

    const startProgressAnimation = () => {
      interval = setInterval(() => {
        context.setProgress((prev) => {
          if (prev < maxProgress) return prev + 1;
          clearInterval(interval);
          return prev;
        });
      }, incrementRate);
    };

    startProgressAnimation();

    // Check if generation finished early
    if (context.generationFinished) {
      clearInterval(interval);
      animateTo100();
    }

    return () => clearInterval(interval);
  }, [context.generationFinished]);

  return (
    <div className="col col__1">
      <Notification text={`${context.progress}%`} />
      <div className="generating">
        <div className="generating__container">
          <GeneratingLoader />
        </div>
      </div>
    </div>
  );
};

export default Generating;
